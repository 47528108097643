<template>
  <div class="all-video-modules-page">
    <v-row justify="center">
        <v-col md="6">
            <intract-smart-list paginated disable-search ref="all-video-modules-smart-list"  :endpoint="getAllModulesEndpoint" :item-options="{'title': 'title'}">
                <template v-slot:list-item="{ item: moduleItem }">
                    <div>
                        <v-list-item class="non-focused"
                            @click="
                                $router.push({
                                name: 'AllVideoLectures',
                                params : { moduleId: moduleItem.id, moduleName: moduleItem.title}
                                })
                            ">

                            <!-- <v-list-item-title class="font-weight-medium my-5">{{ moduleItem.title }}</v-list-item-title>
                            -->

                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium my-1">{{ moduleItem.title }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ moduleItem.videos_count }} videos        
                                </v-list-item-subtitle>    

                            </v-list-item-content>
                            <!-- <v-list-item-icon>
                                <v-badge 
                                inline
                                color="primary" 
                                class="ma-1" 
                                content="7"
                                >
                                </v-badge>
                            </v-list-item-icon> -->

                            <intract-drop-down-menu 
                                v-if="(!moduleItem.creator && !currentUser.is_student) || currentUser.id == moduleItem.creator || currentUser.is_admin"
                                :options-list="getDropdownOptions(moduleItem)"
                            />
                        </v-list-item>
                        <v-divider class="mx-4"></v-divider>
                    </div>
                </template>       
            </intract-smart-list>
        </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="videoModuleCreateEditSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    
    <intract-create-edit-sheet
      persistent
      :title="videoModuleCreateEditSheet.editId ? 'Edit Video Module' : 'Create Video Module'"
      description="A Video Module can consist of various learning video resources for your students"
      :visible="videoModuleCreateEditSheet.visible"
      @close="
        videoModuleCreateEditSheet.visible = false;
        videoModuleCreateEditSheet.editId = null;
      "
      :submit-button-text="videoModuleCreateEditSheet.editId ? 'Edit module' : 'Create module'" 
      :fields="formFields"
      :endpoint="endpoints.videolecturesModulesViewSet"
      :edit-id="videoModuleCreateEditSheet.editId"
      :edit-success-message="videoModuleCreateEditSheet.editSuccessMessage"
      :data-object="videoModuleCreateEditSheet.obj"
      :create-success-message="videoModuleCreateEditSheet.createSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (videoModuleCreateEditSheet.obj = obj)"
    />
    
    <!-- DELETE CONFIRMATION DIALOG -->
    <confirmation-dialog
        title="Are you sure you want to delete this Video module?"
        description="This will also delete ALL the videos inside of this module. This action cannot be undone."
        :visible="showDeleteDialog"
        delete-dialog
        @successCallback="deleteModuleItem"
        @failureCallback="showDeleteDialog = false"
    />  
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import EventBus from '@utils/event_bus';
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractCreateEditSheet, { CreateEditFieldTypes, } from "@components/generics/IntractCreateEditSheet.vue";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';

export default {
    name: "AllVideoModules", 
    mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarTitle],
    
    components: {
        IntractCreateEditSheet,
        IntractSmartList,
        IntractDropDownMenu, 
        ConfirmationDialog
    }, 

    data() {
        return {
            subjectId: this.$route.params.subjectId,
            appBarTitle: null, 
            showDeleteDialog: false, 
            toBeDeletedItem: null,
            videoModuleCreateEditSheet: {
                visible: false,
                createSuccessMessage: "Video Module created successfully!",
                editId: null,
                obj: {
                    subject: this.$route.params.subjectId,
                    creator: null,
                },
            },
        }
    }, 
    
    computed: {
        formFields() {
            return {
                title: {
                    fieldType: CreateEditFieldTypes.TEXTFIELD,
                    label: "Title",
                    required: true,
                    md: 12,
                    max: 255,
                },

            };
        },

        getAllModulesEndpoint() {
            var url = this.Helper.addUrlParams(this.endpoints.videolecturesModulesViewSet, ["subject=" + this.subjectId]);
            return url;
        },
    }, 

    methods: {
        async onRefresh() {
            if(this.$refs["all-video-modules-smart-list"])this.$refs["all-video-modules-smart-list"].getItems();
        },

        getDropdownOptions(moduleItem) {
            var self = this;
            return [
                {
                    value: 1, 
                    text: "Edit", 
                    icon: "mdi-pencil",
                    type: "edit",
                    action() {
                        self.videoModuleCreateEditSheet.editId = moduleItem.id;
                        self.videoModuleCreateEditSheet.visible = true;
                    }                  
                }, 
                {
                    value: 2,
                    text: "Delete",
                    icon: "mdi-delete",
                    type: "delete",
                    action() {
                        self.toBeDeletedItem = moduleItem;
                        self.showDeleteDialog = true;
                    }          
                }
            ];
        },

        async deleteModuleItem() {
            var url = this.endpoints.videolecturesModulesViewSet + this.toBeDeletedItem.id + "/";
            this.toBeDeletedItem = null;
            this.showDeleteDialog = false;
            console.log(url)
            var response = await this.api.call(
                this.essentials,
                url, 
                this.api.Methods.DELETE
            );
            console.log(response)
            if (response) {
                this.showSnackbar({
                title: "Video module has been successfully deleted!",
                type: "success",
                });
                console.log("test")
            }
            this.$refs["all-video-modules-smart-list"].getItems();   
        },
    }, 

    created() {
        this.appBarTitle = this.$route.params.subjectName ?? "Modules"; 
        this.videoModuleCreateEditSheet.obj.creator = this.currentUser.id;
    }, 
  
    beforeRouteLeave(to, from, next) {
        if(to.name != "AllVideoLectures"){

            //kill component    
            EventBus.$emit('keep_alive__destroy_view', 'AllVideoModules');
        }
        next();
    }, 
}
</script>

<style>

</style>